import React from 'react';
import { graphql } from 'gatsby'

const Post = (props) => {
  return (
    <div>
    {props.data.prismicPost.data.post_desription[0].text}
    </div>
  )
}

export const postQuery = graphql`
  query Post($slug: String) {
    prismicPost(id: { eq: $slug }) {
      data {
        post_date
        post_desription {
          text
        }
      }
    }
  }
`

export default Post;